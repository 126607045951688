import React from "react";
import "./Question.css";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import StarRateIcon from "@material-ui/icons/StarRate";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const optionIcons = {
  dolar1: <SearchIcon fontSize="default" />,
  dolar2: <StarRateIcon fontSize="default" />,
  dolar3: <FormatListBulletedIcon fontSize="default" />,
};

const Question = ({ options, nextQuestion }) => {
  const renderOptions = options.map(({ icon, caption }) => (
    <Grid item onClick={nextQuestion} xs key={`${caption} ${icon}`}>
      <div className="option">
        <IconButton className="optionIcon">
          {optionIcons[icon]}
        </IconButton>
        <div className="caption">
          <Typography variant="subtitle1" gutterBottom>
            {caption}
          </Typography>
        </div>
      </div>
    </Grid>
  ))
  return (
    <Grid alignItems="center" container className="optionButtons">
      {renderOptions}
    </Grid>
  );
};

export default Question;
