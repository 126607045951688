import React from "react";
import "./SearchResult.css";
import database from "./firebase";
import { Typography } from "@material-ui/core";

const SearchResult = () => {
  const renderResults = database.map(({ url, name, igUrl }) => (
    <div
      style={{ backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.5284488795518207) 64%, rgba(255,255,255,0.08587184873949583) 100%), url(${url})` }}
      className="result"
      onClick={() => window.open(`${igUrl}?utm_source=ig_web_copy_link`, "_blank")}
      >
      {name}
    </div>
  ));
  renderResults.sort(() => Math.random() - 0.5);
  return (
    <div className="searchResult">
      <Typography variant="h6">Cari Kopi</Typography>
      {renderResults}
    </div>
  );
};

export default SearchResult;
