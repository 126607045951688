import React, { useState, useEffect } from "react";
import TinderCard from "react-tinder-card";
import "./TinderCards.css";
import database from "./firebase";
import { Grid, Typography } from "@material-ui/core";

function TinderCards() {
  const [people, setPeople] = useState([]);

  useEffect(() => {
    setPeople(database);

    return () => {
      setPeople([]);
    };
  }, []);

  const swiped = (direction, url) => {
    if (direction === 'right') {
      window.open(`${url}?utm_source=ig_web_copy_link`);
    }
  }

  return (
    <div>
      <div className="tinderCards__cardContainer">
        {people.map((person) => (
          <TinderCard
            className="swipe"
            key={person.name}
            preventSwipe={["up", "down"]}
            onSwipe={(dir) => swiped(dir, person.igUrl)}
            onClick={() => window.open(`${person.igUrl}?utm_source=ig_web_copy_link`)}>
            <div
              style={{ backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0.1) 70%), url(${person.url})` }}
              className="card">
              <Grid container alignItems="flex-end" className="cardInfo">
                  <Grid item xs={12} className="cardCaption">
                    <Typography variant="h5" className="tenantName">
                      {person.name}
                    </Typography>
                    <Typography variant="caption" className="tenantLocation">
                      {person.location}
                    </Typography>
                  </Grid>
                </Grid>
            </div>
          </TinderCard>
        ))}
      </div>
    </div>
  );
}

export default TinderCards;
