import React from "react";
import "./SwipeButtons.css";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import StarRateIcon from "@material-ui/icons/StarRate";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from "react-router";

const SwipeButtons = () => {
  const history = useHistory();
  return (
    <div className="swipeButtons">
      <IconButton className="swipeButtons__left" onClick={() => history.push('/search')}>
        <SearchIcon fontSize="large" />
      </IconButton>
      <IconButton className="swipeButtons__star" onClick={() => history.push('/')}>
        <StarRateIcon fontSize="large" />
      </IconButton>
      <IconButton className="swipeButtons__right">
        <FormatListBulletedIcon fontSize="large" />
      </IconButton>
    </div>
  );
};

export default SwipeButtons;
