import Typography from "@material-ui/core/Typography";
import Question from './Question';
import React, { useState } from "react";
import "./Search.css";
import { useHistory } from "react-router";

const questions = [
  {
    title: 'Besar Dompet?',
    options: [
      {
        icon: 'dolar1',
        caption: 'Jelata',
      },
      {
        icon: 'dolar2',
        caption: 'Pekerja',
      },
      {
        icon: 'dolar3',
        caption: 'Sultan',
      }
    ],
  },
  {
    title: 'Mood Saat Ini?',
    options: [
      {
        icon: 'dolar2',
        caption: 'Chill',
      },
      {
        icon: 'dolar1',
        caption: 'Work',
      },
      {
        icon: 'dolar3',
        caption: 'Lapar',
      }
    ],
  },
  {
    title: 'Tipe Kopinya?',
    options: [
      {
        icon: 'dolar3',
        caption: 'Manual Brew',
      },
      {
        icon: 'dolar1',
        caption: 'Espresso',
      },
      {
        icon: 'dolar2',
        caption: 'Exotic',
      },
    ],
  },
];

const Search = () => {
  const [questionNumber, setQuestionNumber] = useState(0);
  const history = useHistory()
  const nextQuestion = () => {
    if (questionNumber + 1 === questions.length) {
      history.push('/searchResult')
      return;
    }
    setQuestionNumber(questionNumber + 1);
  };

  return (
    <div className="search">
      <Typography variant="h5" gutterBottom>
        {questions[questionNumber].title}
      </Typography>
      <Question options={questions[questionNumber].options} nextQuestion={nextQuestion} />
    </div>
  );
};

export default Search;
